<template>
  <div class="tutorial-screen-inner align-items-center">
    <div class="tutorial-screen-left">
      <img src="/imgs/auto-mapping-step1.png" alt="Auto Mapping Tutorial" />
    </div>
    <div class="tutorial-screen-right">
      <h3 class="sub-title">{{ info.subTitle }}</h3>
      <h3 class="screen-title">{{ info.title }}</h3>
      <p class="desc mt">
        Artikel, deren Mapping Vorschlagswerte eine Wahrscheinlichkeit von
        mindestens 75% haben, können unter “Bearbeiten Auto Mapping“ gesichtet
        und exportiert werden.
      </p>
      <div class="desc">
        Sie können dabei entscheiden, welche Prozentzahl (Mapping
        Wahrscheinlichkeit <strong>95%, 85%, 75%</strong> ) Sie als
        <strong>Kriterium</strong>
        anwenden möchten.
      </div>
      <p class="desc">
        Nach dem Export, der zu einem Download einer CSV Datei führt, stehen nur
        noch die Artikel zur Auswahl, bei denen Sie noch keinen Export
        vorgenommen haben.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        subTitle: "AUTO-MAPPING",
        title: "Bei Wahrscheinlichkeiten ab 75%"
      }
    };
  }
};
</script>

<style lang="scss">
.tutorial-screen {
  &-inner {
    padding-right: 40px;
    padding-left: 65px;
    display: flex;
  }
  &-left {
    min-width: 420px;
    img {
      width: 100%;
    }
  }
  &-right {
    margin-left: 65px;
    .screen-title {
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 32px;
      color: #555555;
    }
    .sub-title {
      font-size: 18px;
      line-height: 22px;
      color: #ee7f00;
      margin-bottom: 10px;
    }
    .desc {
      font-family: Barlow;
      font-size: 16px;
      line-height: 24px;
      color: #555555;
      margin-bottom: 15px;
      strong {
        font-family: Barlow;
        font-weight: bold;
      }
      &.mt {
        margin-top: 25px;
      }
    }
  }
}
</style>